import { Injectable, computed, inject, signal } from '@angular/core';
import { ApiService } from '@mca/shared/util';
import { httpHoliday, httpRefModule } from '../infrastructure/references-http-points';
import { dateAsYYYYMMDD, localDateAtUtc } from '@mca/shared/util';
import { catchError, filter, finalize, map, of } from 'rxjs';
import { isWeekend, toDate, differenceInBusinessDays, compareAsc } from 'date-fns';
import { toObservable } from '@angular/core/rxjs-interop';

export interface Holiday {
  id: number;
  date: string;
  dateObject: Date;
  country: 'US';
}

@Injectable({
  providedIn: 'root',
})
export class HolidaysService {
  private apiService = inject(ApiService);

  private holidays = signal([] as Holiday[]);
  loading = signal(false);

  loadedHolidays = computed(() => !this.loading() && this.holidays());
  loadedHolidays$ = toObservable(this.loadedHolidays).pipe(filter((holidays): holidays is Holiday[] => Array.isArray(holidays)));

  holidaysDates = computed(() => this.holidays().map(data => data.dateObject));
  holidaysMap = computed(() => new Set(this.holidaysDates().map(d => dateAsYYYYMMDD(d))));

  fetchHolidays() {
    if (!this.holidays().length) {
      this.loading.set(true);
    }
    this.getHolidays()
      .pipe(
        map(items =>
          items
            .map((item: Partial<Holiday>) => {
              const utcDate = localDateAtUtc(new Date((item as Holiday).date));
              return { ...item, dateObject: utcDate, date: dateAsYYYYMMDD(utcDate) } as Holiday;
            })
            .sort((a: Holiday, b: Holiday) => compareAsc(a.dateObject, b.dateObject)),
        ),
        catchError(() => of([])),
        finalize(() => this.loading.set(false)),
      )
      .subscribe(holidays => this.holidays.set(holidays));
  }

  isHoliday(date: Date) {
    return this.holidaysMap().has(dateAsYYYYMMDD(date));
  }

  isBusinessDay(date: Date) {
    return !isWeekend(date) && !this.isHoliday(date);
  }

  forwardToBusinessDay(date: Date) {
    return this.isBusinessDay(date) ? date : this.addBusinessDays(date, 1);
  }

  addBusinessDays(date: Date, amount: number) {
    const sign = Math.sign(amount);
    const result = toDate(date);
    while (amount !== 0) {
      result.setDate(result.getDate() + sign);
      if (this.isBusinessDay(result)) {
        amount -= sign;
      }
    }
    return result;
  }

  businessDiff(left: Date, right: Date) {
    const [from, to] = [left, right].sort(compareAsc);
    const holidaysDiffInBetween = to > from ? this.holidaysDates().filter(v => v > from && v < to && !isWeekend(v)).length : 0;
    const fromIsHoliday = this.isHoliday(from) && !isWeekend(from);
    const toIsHoliday = this.isHoliday(to) && !isWeekend(to);
    // eslint-disable-next-line no-bitwise
    const holidaysDiffOnEdges = +fromIsHoliday & +toIsHoliday;
    return differenceInBusinessDays(to, from) - holidaysDiffInBetween - holidaysDiffOnEdges;
  }

  /**
   * CRUD methods
   */

  private getHolidays() {
    return this.apiService.get(httpRefModule('holidays'));
  }

  createHolidays(body: Holiday) {
    return this.apiService.post(httpRefModule('holidays'), body);
  }

  updateHolidays(id: number, body: Holiday) {
    return this.apiService.put(httpHoliday(id), body);
  }

  deleteHolidays(id: number) {
    return this.apiService.delete(httpHoliday(id));
  }
}
